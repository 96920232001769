import React, { useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../components/carousel.scss';
import Img from 'gatsby-image';

function MyCarousel ({ images, customHeight = undefined, options = {}, fullImg = false, withSubtitle = false }) {
  const [height, setHeight] = React.useState(0);
  const [activeImage, setActiveTitle] = React.useState((images && images[0]) || null);
  const hasWindow = typeof window !== 'undefined' && window;
  useEffect(() => {
    if (hasWindow) {
      const newHeight = customHeight
        ? (Math.round(window.innerHeight / customHeight))
        : (window.innerHeight - 64);
      setHeight(newHeight);
    }
  }, [hasWindow]);

  function handleChange (i, child) {
    return withSubtitle && images[i] && setActiveTitle(images[i]);
  }
  const opts = {
    dynamicHeight: true,
    infiniteLoop: true,
    swipeable: true,
    autoPlay: true,
    transitionTime: true,
    showArrows: false,
    interval: 3500,
    renderThumbs: () => null,
    onChange: handleChange,
    ...options
  };

  const imgProps = fullImg ? {
    imgStyle: {
      height: 'auto',
      width: '100%',
      margin: '0 auto',
      display: 'block',
      left: 0,
      right: 0,
      bottom: 0
    }
  } : {
    imgStyle: { height }
  };
  return (
    <div className={'is-block'}>
      <Carousel
        {...opts}>
        {images && images.map(({ image: { id, childImageSharp: { fluid } }, title, text: banner }) =>
          <div style={{ height: height }} key={id}>
            <Img fluid={fluid}
              style={{ position: 'relative', height: fullImg ? 'auto' : height, width: fullImg ? '60%' : 'auto', margin: '0 auto' }}
              alt={title || 'Franziska Schittler Restaurierung in München'} objectFit="fit" {...imgProps} />
            {!withSubtitle && banner && <p className={'legend is-size-3-desktop is-size-6-mobile is-weight-400'}>{banner}</p>}
          </div>
        )}
      </Carousel>
      {withSubtitle && activeImage &&
        <p className={'legend is-size-3-desktop is-size-6-mobile is-weight-400'}>{activeImage.title}</p>
      }
    </div>
  );
}

export default MyCarousel;
